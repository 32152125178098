<template>
  <div class="container">
    <headeBar title="包含权益" left-arrow @click-left="appBack" />
    <div class="content">
      <div class="info-box">
        <div class="b1 row-between-center">
          <p class="fw6 f24">{{ userEquityCardVO.equityCardName }}</p>
          <p v-if="!equityCardGiftId" class="f22" v-html="status[userEquityCardVO.status].htmlSellRecord"></p>
        </div>
        <p class="p1 f20">购买时间：{{ userEquityCardVO.buyDate}}</p>
          <p v-if="userEquityCardVO.status === 'WAIT_OPEN'" class="p1 f20">
              <template v-if="userEquityCardVO.openDeadlineDate">生效期：下次进馆自动生效，在{{ userEquityCardVO.openDeadlineDate }}前未进馆则自动{{ (userEquityCardVO.isGivingCard == 0 && userEquityCardVO.source === 'FRIEND_GIVING')? '生效': '退款'}}</template>
              <template v-else>生效期：下次进馆自动生效，永久有效</template>
          </p>
        <p class="p1 f20" v-else-if="userEquityCardVO.status === 'WAIT_ACTIVE'">生效期：按购买时间依次生效后计算有效期</p>
        <p class="p1 f20" v-else-if="userEquityCardVO.status === 'ACTIVE'">生效期：{{ userEquityCardVO.validityStartDate }}-{{ userEquityCardVO.validityEndDate }}</p>
        <p class="p1 f20" v-if="equityCardGiftId && userEquityCardVO.givingDeadlineDate">说明：{{ userEquityCardVO.givingDeadlineDate }} 前未赠送将原路退卡退款</p>
        
      </div>
      <div class="equity-box">
        <p class="p1 f24 fw6">包含以下权益包</p>
        <p class="p2 f20 ">卡生效后依次发放</p>
        <div class="equity-item flex" v-for="item in discountCoupons" :key="item.equityName">
          <div class="icon" :style="`background-image: url(${item.equityIconUrl})`"></div>
          <div class="desc">
            <p class="t1 f24">{{ item.equityName }}</p>
            <p class="t2 f20">{{ item.equityInstructions }}</p>
            <p v-if="item.isTimeLimitedDiscount === 1 || item.equityType === 1" class="t1 f22" :style="{ color: status['ACTIVE'].color }">{{ item.equityLabel }}</p>
          </div>
        </div>
          <div class="equity-item flex" v-for="item in userEquityCardEquityVOList" :key="item.equityName">
          <div class="icon" :style="`background-image: url(${item.equityIconUrl})`"></div>
          <div class="desc">
            <p class="t1 f24">{{ item.equityName }}</p>
            <p class="t2 f20">{{ item.equityInstructions }}</p>
            <p v-if="item.isTimeLimitedDiscount === 1 || item.equityType === 1" class="t1 f22" :style="{ color: status['ACTIVE'].color }">{{ item.equityLabel }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import headeBar from '@/components/app/headBar'
import { status } from './const.js'
import wx from 'weixin-js-sdk'

export default {
  mixins: [userMixin],
  components: {
    headeBar,
  },
  data() {
    return {
      userEquityCardEquityVOList: [], // 权益列表
      discountCoupons: [], // 权益列表
      userEquityCardVO: {
        status: 'ACTIVE',
      }, // 卡详情
      userMembershipCardId: '',
      equityCardGiftId: '',
      
      status,

    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo()
    initBack()
    this.userMembershipCardId = getParam().userMembershipCardId
    this.equityCardGiftId = getParam().equityCardGiftId
    if(this.equityCardGiftId){
        this.getGiftCardDetail()
    }else{
        this.getCardDetail()
    }
  },
  methods: {
    appBack: newAppBack,
    getGiftCardDetail() {
        this.$axios.post(`${this.baseURLApp}/user/giving/equityCard/detail`, {
            userId: this.userId,
            token: this.token,
            userEquityCardGiftId: this.equityCardGiftId
        }).then((res) => {
            let cardInfo = res.data.userEquityCardEquityVOList || {}
            this.userEquityCardEquityVOList = cardInfo
            this.userEquityCardVO = res.data.userEquityCardGiftVO
        })
    },
    getCardDetail() {
      this.$axios.post(`${this.baseURLApp}/user/equityCard/detail`, {
          userId: this.userId,
          token: this.token,
          userEquityCardId: this.userMembershipCardId,
      }).then((res) => {
        this.userEquityCardEquityVOList = res.data.userEquityCardEquityVOList
        this.discountCoupons = res.data.discountCoupons
        this.userEquityCardVO = res.data.userEquityCardVO
      })
    },
    
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 0 0 32px;

  * {
    box-sizing: border-box;
  }
}

.content {
  .info-box {
    border-bottom: 16px solid #F5F5F5;
    padding: 38px 32px 24px;
    .b1 {
      margin: 0 0 16px;
    }
    .p1 {
      margin: 0 0 8px;
      color: #6C727A;
    }
  }
  .equity-box {
    padding: 38px 32px;
    .p2 {
      color: #6C727A;
      margin: 4px 0 0;
    }
    .equity-item {
      margin: 40px 0 0;
      .icon {
        width: 168px;
        height: 140px;
        background: #FFFDD1;
        border-radius: 8px;
        background-size: cover;
          flex-shrink: 0;
      }
      .desc {
        margin: 0 0 0 32px;
        .t2 {
          color: #6C727A;
          margin: 8px 0 16px;
        }
      }
    }
  }
}


</style>
